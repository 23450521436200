:root {
  /* --main-bg-color: #25282e;
  --main-navbar-color: #16181c;
  --navbar-text-color: #FFFFFF;
  --navbar-hover-color: #3e424a;
  --content-text-color: #FFFFFF;
  --content-highlight: #6b82b0; */
  --main-bg-color: #eff0f3;
  --main-navbar-color: #fffffe;
  --navbar-text-color: #0d0d0d;
  --navbar-hover-color: #ff8e3c;
  --nav-font: 'Montserrat', sans-serif;
  --content-text-color: #0d0d0d;
  --content-highlight: #6b82b0;
  --content-font: 'Montserrat', sans-serif;
}

.App {
  display:flex;
  flex-direction: column;
  align-items: center;
  /* margin-left: auto;
  margin-right: auto;
  width: 50rem; */
  /* max-width: 1223px; */
  /* border-top: 2px solid orange; */
  
}

#root{
  background-color: var(--main-navbar-color);
  height: 100vh;
  overflow-y: auto;
}

@media (max-width:900px) {
  .App {
    display:flex;
    flex-direction: column;
    margin: 0px;
    width:100%;
  }


}

