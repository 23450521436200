h1 {
  margin: 0;
}

.home-content header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding:100px 0px 100px;
  background-color: var(--main-bg-color);
  width: 100%;
}

.home-content header span{
  height: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
}

.dice {
  width:50px;
  height:50px;
  padding: 10px;
  left: 60%;
}

.dice:hover {
  animation: shake 0.5s;
  animation-iteration-count: infinite;
}

.picture {
  max-width: 20rem;
  margin: 20px;
}

.home-content {
  /* padding: 0px 10px 10px 10px; */
  display: flex;
  /* max-width: 44rem; */
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.home-bio {
  /* max-width: 15rem; */
  width: 50rem;
  margin: 0px 20px 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}

