.project-block {
    background: var(--main-bg-color);
    display: flex;
    flex-direction: row;
    max-width: 44rem;
    height:17rem;
    align-items: center;
    margin: 20px auto 0px;
}

.project-block img{
    width:12rem;
    height:12rem;
}

.project-content header {
    width: 460px;
    display: flex;
    flex-direction: column;
    margin: 10px;
}

.github {
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.github a{
    text-decoration: none;
    font-weight: bold;
    background-color: var(--main-navbar-color);
    margin: 2px 0px 0px;
    padding: 2px 10px;
    color: var(--navbar-text-color);
}

.github a:hover{
    border-bottom: 2px solid var(--navbar-hover-color);
    margin: 0px;
}

@media (max-width:1250px) {
    .project-block {
        flex-direction: column;
        width: 600px;
        height: auto;
        align-items: center;
    }
    
    .github a{
        width: auto;
    }
}