.content {
  /* margin: 1rem 1rem; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* width: 50rem; */
  width: 100%;
  margin-top:140px;
  /* overflow-y: auto; */
  /* height: 100%; */
}

p, h1 {
  color: var(--content-text-color);
  font-family: var(--nav-font);
}

span {
  color: var(--content-highlight);
  padding-left: 10px;
}

h1 {
  font-size: 30px;
}

p {
  font-size: 18px;
}
@media (max-width:900px) {
  .content {
    overflow-y: auto;
    height: calc(100vh - 300px);
    display: block;
    padding-top: 302px;
    width:100%;
    margin: 0px;
  }

  ::-webkit-scrollbar {
    display: none;
}
}