.nav {
  display:flex;
  flex-direction: row;
  align-items: center;
  top: 0;
  /* width: 50rem; */
  width:100%;
  position: fixed;
  border-bottom: 2px solid orange;
  background: var(--main-navbar-color);
  margin: 0px auto 0px;
  justify-content: center;
  /* padding-top: 20px; */

}

.title {
  height: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* color: var(--navbar-text-color);
  text-shadow: 5px 3px var(--content-highlight); */
  padding-bottom: 20px;
  padding-top: 20px;
}

.titletext {
  color: var(--content-highlight);
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  font-family: var(--nav-font), sans-serif;;
  user-select: none;
}
.nav a {
  text-decoration: none;
  color: var(--navbar-text-color);
  width: 10%;
  height: 10%;
  /* background: var(--main-navbar-color); */
  /* transition: background 0.5s ease-in-out; */
  font-family:var(--nav-font), sans-serif;
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  -webkit-user-drag: none;
}

.navlink {
  /* for linkedin and github icons */
  display: flex;
  justify-content: center;
  align-items: center;
}
.nav a:hover {
  /* background: var(--navbar-hover-color); */
  border-bottom: 2px solid var(--navbar-hover-color);
}

.icon {
  width: 10%;
}

@media (max-width:900px) {
  .nav {
    padding-top: 0px;
    flex-direction: column;
    height: auto;
    border-bottom: 2px solid orange;
    border-right: 0;
    width: 100%;
  }

  a {
    height: auto;
    width: 100%;
  }

  .icon {
    width: 20px;
  }
}