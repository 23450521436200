:root {
    --border: 3px;
    --from: 30deg;
    --distance: 46%;
}

.resume-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.resume-header {
    color: var(--content-highlight);
    text-align: center;
    padding:10px 0px 10px;
}

.education-block {
    display:flex;
}

.education-picture {
    width:10rem;
    height:10rem;
}

.education-title {
    font-size: 25px;
}

.education-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.img-wrap {
    position: relative;
    padding: 30px;
    margin-right: 20px;
  }
  .img-wrap::after {
    content: '';
    border-radius: 999px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: conic-gradient(
      from var(--from),
      orange,
      var(--content-highlight) var(--distance),
      transparent var(--distance)
    );
    -webkit-mask: radial-gradient(
      farthest-side,
      transparent calc(100% - var(--border)),
      #fff calc(100% - var(--border) + 1px)
    );
    mask: radial-gradient(
      farthest-side,
      transparent calc(100% - var(--border)),
      #fff calc(100% - var(--border) + 1px)
    );
  }


.experience-block{
  display: flex;
  flex-direction: row;
  align-items: center;
  border-top: 2px solid var(--main-bg-color);
  width: 50rem;
}

.experience-block h1{
  text-align: center;
  font-size: 16px;
}

.experience-block p{
  font-size: 15px;
}

.experience-title {
  padding-top: 10px;
}

.experience-block img{
  width:12rem;
  height:12rem;
}

@media (max-width:900px) {
  .experience-block {
      flex-direction: column;
      width: 600px;
      height: auto;
      align-items: center;
  }
}

@media (max-width:700px) {
  .experience-block {
      flex-direction: column;
      width: 90%;
      height: auto;
      align-items: center;
  }
}